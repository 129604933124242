import React from 'react';
import { object } from 'prop-types';
import root from 'window-or-global';
import loadable from '@loadable/component';

const SubscriptionFactory = loadable(() => import('components/subscription-factory'));
const BuySubscription = loadable(() => import('components/subscription/buy'));

const Grid = loadable(() => import('@material-ui/core/Grid'));

const GoldSubscriptionView = ({ subscriptionPrices }) => {
  const {
    gold = {},
  } = subscriptionPrices;

  return (
    <Grid spacing={1}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
     >
      <Grid item xs={12} md={10}>
        <span id="benefits" />
        <SubscriptionFactory{...{
          subscription: 'gold',
          collapsed: true,
          models: subscriptionPrices,
          onSelect: () => {
            setTimeout(() => {
              const elmnt = root.document.getElementById('benefits');
              if (elmnt) {
                elmnt.scrollIntoView({ behavior: 'smooth', inline: 'start' });
              }
            }, 100);
          },
        }}/>

      <BuySubscription model={gold}/>
      </Grid>
    </Grid>
  );
};

GoldSubscriptionView.propTypes = { subscriptionPrices: object.isRequired };

export default GoldSubscriptionView;
